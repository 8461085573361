import { PureComponent } from 'react';

import Html from 'Component/Html';
import { ImageStickersType } from 'Type/ImageStickersType';

import './ImageSticker.style';

/** @namespace Pwa/Component/ImageSticker/Component */
export class ImageStickerComponent extends PureComponent {
    static propTypes = {
        tag: ImageStickersType.isRequired,
    };

    render() {
        const { tag: { position, content } } = this.props;

        if (!content) {
            return null;
        }

        return (
            <div block="ImageSticker" mods={ { Position: position } }>
                <Html content={ content } />
            </div>
        );
    }
}

export default ImageStickerComponent;
