import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { CENTO } from 'Component/BlackFridayCounter/BlackFridayCounter.config';
import CmsPopup from 'Component/CmsPopup/CmsPopup.component';
import Image from 'Component/Image';
import InfoIcon from 'Component/InfoIcon/InfoIcon.container';
import { BlackFridayCounterType } from 'Type/BlackFridayCounterType';

import './BlackFridayCounter.style';

/** @namespace Pwa/Component/BlackFridayCounter/Component */
export class BlackFridayCounterComponent extends PureComponent {
    static propTypes = {
        sku: PropTypes.string.isRequired,
        isInfoIcon: PropTypes.bool,
        isCompactVersion: PropTypes.bool,
        blackFriday: BlackFridayCounterType.isRequired,
        mix: PropTypes.object,
        countdown: PropTypes.shape({
            days: PropTypes.number,
            hours: PropTypes.number,
            minutes: PropTypes.number,
            seconds: PropTypes.number,
        }).isRequired,
    };

    static defaultProps = {
        isInfoIcon: false,
        isCompactVersion: true,
        mix: {},
    };

    renderSlideBar() {
        const {
            blackFriday: { items_total, items_remaining }, sku, isInfoIcon,
        } = this.props;
        const percentage = `${(CENTO * items_remaining) / items_total }%`;

        return ((items_total > 0)
            && (
                <>
                    <div block="BlackFridayCounterIndicator" elem="StockAvailabilitySlider">
                        <div
                          block="BlackFridayCounterIndicator"
                          elem="StockAvailabilityCurrent"
                          style={ { width: percentage } }
                        />
                    </div>
                    { isInfoIcon
                        && <InfoIcon id={ sku } /> }
                </>
            )
        );
    }

    renderCountdownText() {
        const {
            countdown: {
                days, hours, minutes, seconds,
            },
        } = this.props;

        return ((days + hours + minutes + seconds > 0)
            && (
                <div block="BlackFridayCounterIndicator" elem="CountdownText">
                    <i className="fa fa-clock-o" aria-hidden="true" />
                    &nbsp;
                    { __('Offer on time and while stocks last') }
                </div>
            ));
    }

    renderCountdown() {
        const {
            countdown: {
                days, hours, minutes, seconds,
            },
        } = this.props;

        if (window.location.pathname !== '/') {
            return ((days + hours + minutes + seconds > 0)
            && (
                <span block="BlackFridayCounterIndicator" elem="Countdown">
                    { __('Ends in') }
                    &nbsp;
                    { days > 0 && (
                        <strong>
                            { days }
                            d
                        </strong>
                    ) }
                    &nbsp;
                    { hours > 0 && (
                        <strong>
                            { hours }
                            h
                        </strong>
                    ) }
                    &nbsp;
                    { minutes > 0 && (
                        <strong>
                            { minutes }
                            m
                        </strong>
                    ) }
                    &nbsp;
                    <strong>
                    { seconds }
                        s
                    </strong>
                    &nbsp;
                </span>
            )
            );
        }

        return (<span block="BlackFridayCounterIndicator" elem="Countdown" />);
    }

    renderInfoPopUp() {
        const { blackFriday: { cms_block }, sku } = this.props;

        return (
            cms_block && <CmsPopup id={ sku } cms_block={ cms_block } />
        );
    }

    renderBlackFridayClosed() {
        const { blackFriday: { closed_image } } = this.props;

        return (
            <>
                    <Image
                      src={ closed_image }
                      mix={ {
                          block: 'Footer',
                          elem: 'Logo',
                      } }
                    />
                    <div block="BlackFridayCounterIndicator">
                    Siamo spiacenti, la promozione del BlackFriday su questo articolo è terminata
                    </div>
            </>
        );
    }

    renderCopy() {
        const {
            blackFriday: { items_remaining },
        } = this.props;

        // eslint-disable-next-line fp/no-let
        let copyText = '';

        if (items_remaining > 1) {
            copyText = __('Only Products Left');
        } else if (items_remaining === 1) {
            copyText = __('Only Product Left');
        } else {
            copyText = __('Sold Out');
        }

        if (window.location.pathname === '/') {
            return (
                <>
                    <br />
                    { items_remaining < 1
                        ? copyText
                        : items_remaining === 1
                            ? `${copyText} ${items_remaining} ${__('prodotto!')}`
                            : `${copyText} ${items_remaining} ${__('products!')}` }
                </>
            );
        }

        return (
            <>
                <br />
                { items_remaining < 1
                    ? copyText
                    : items_remaining === 1
                        ? `${copyText} ${items_remaining} ${__('prodotto!')}`
                        : `${copyText} ${items_remaining} ${__('products!')}` }
            </>
        );
    }

    renderBlackFridayActive() {
        const {
            isCompactVersion,
        } = this.props;

        return (
            <>
                { isCompactVersion && this.renderCopy() }
                { this.renderCopy() }
            </>
        );
    }

    renderContent() {
        const { blackFriday: { is_closed } } = this.props;

        if (is_closed) {
            return this.renderBlackFridayActive();
        }

        return this.renderBlackFridayActive();
    }

    render() {
        const {
            blackFriday: { discount_code },
            mix,
        } = this.props;

        return (
                    <div block="BlackFridayCounterIndicator" mix={ mix } mods={ { DiscountCode: discount_code } }>
                { this.renderContent() }
                    </div>
        );
    }
}

export default BlackFridayCounterComponent;
