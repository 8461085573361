import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Image from 'Component/Image';

import daysBackES from './assets/es/countdown_days.png';
import hoursBackES from './assets/es/countdown_hours.png';
import minutesBackES from './assets/es/countdown_minutes.png';
import secondsBackES from './assets/es/countdown_seconds.png';
import maskImgES from './assets/es/gb_bf_mask.png';
import maskImgHoverES from './assets/es/gb_bf_mask_hover.png';
import daysBackIT from './assets/it/countdown_days.png';
import hoursBackIT from './assets/it/countdown_hours.png';
import minutesBackIT from './assets/it/countdown_minutes.png';
import secondsBackIT from './assets/it/countdown_seconds.png';
import maskImgIT from './assets/it/gb_bf_mask.png';
import maskImgHoverIT from './assets/it/gb_bf_mask_hover.png';

import './PromoMask.style';

/** @namespace Pwa/Component/PromoMask/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    systemStoreCode: state.ConfigReducer.code,
});

/** @namespace Pwa/Component/PromoMask/Component/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Pwa/Component/PromoMask/Component */
export class PromoMaskComponent extends PureComponent {
    static propTypes = {
        countdown: PropTypes.shape({
            days: PropTypes.number,
            hours: PropTypes.number,
            minutes: PropTypes.number,
            seconds: PropTypes.number,
        }).isRequired,
        systemStoreCode: PropTypes.string.isRequired,
    };

    render() {
        const {
            countdown: {
                days, hours, minutes, seconds,
            },
            systemStoreCode,
        } = this.props;

        return (
            <div block="PromoMask">
                    <Image
                      src={ (systemStoreCode === 'spain') ? maskImgES : maskImgIT }
                      mix={ { block: 'PromoMask', elem: 'Background' } }
                    />
                    <Image
                      src={ (systemStoreCode === 'spain') ? maskImgHoverES : maskImgHoverIT }
                      mix={ { block: 'PromoMask', elem: 'Background', mods: { isHover: true } } }
                    />
                    <div block="PromoMask" elem="Countdown" mods={ { with_days: (days > 0) } }>
                            { (days > 0) && (
                                <div block="PromoMask" elem="Item">
                                    <Image src={ (systemStoreCode === 'spain') ? daysBackES : daysBackIT } />
                                    <div block="PromoMask" elem="Number">
                                        { days }
                                    </div>
                                </div>
                            ) }
                            <div block="PromoMask" elem="Item">
                                <Image src={ (systemStoreCode === 'spain') ? hoursBackES : hoursBackIT } />
                                <div block="PromoMask" elem="Number">
                                    { hours }
                                </div>
                            </div>
                            <div block="PromoMask" elem="Item">
                                <Image src={ (systemStoreCode === 'spain') ? minutesBackES : minutesBackIT } />
                                <div block="PromoMask" elem="Number">
                                    { minutes }
                                </div>
                            </div>
                            <div block="PromoMask" elem="Item">
                                <Image src={ (systemStoreCode === 'spain') ? secondsBackES : secondsBackIT } />
                                <div block="PromoMask" elem="Number">
                                    <div>{ seconds }</div>
                                </div>
                            </div>
                    </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoMaskComponent);
