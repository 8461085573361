import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsPopup from 'Component/CmsPopup';
import Image from 'Component/Image';
import InfoIcon from 'Component/InfoIcon';
import { FlashSaleType } from 'Type/FlashSaleType';

import { CENTO } from './FlashSalesIndicator.config';

import './FlashSalesIndicator.style';

/** @namespace Pwa/Component/FlashSalesIndicator/Component */
export class FlashSalesIndicatorComponent extends PureComponent {
    static propTypes = {
        sku: PropTypes.string.isRequired,
        isInfoIcon: PropTypes.bool,
        isCompactVersion: PropTypes.bool,
        flashsale: FlashSaleType.isRequired,
        mix: PropTypes.object,
        countdown: PropTypes.shape({
            days: PropTypes.number,
            hours: PropTypes.number,
            minutes: PropTypes.number,
            seconds: PropTypes.number,
        }).isRequired,
    };

    static defaultProps = {
        isInfoIcon: true,
        isCompactVersion: true,
        mix: {},
    };

    renderSlideBar() {
        const {
            flashsale: { items_total, items_remaining }, sku, isInfoIcon,
        } = this.props;
        const percentage = `${(CENTO * items_remaining) / items_total }%`;

        return ((items_total > 0)
            && (
                <>
                    <div block="FlashSalesIndicator" elem="StockAvailabilitySlider">
                        <div
                          block="FlashSalesIndicator"
                          elem="StockAvailabilityCurrent"
                          style={ { width: percentage } }
                        />
                    </div>
                    <span>
                        { items_remaining }
                        &nbsp;
                        { __('remaining products') }
                        &nbsp;
                    </span>
                    { isInfoIcon
                        && <InfoIcon id={ sku } /> }
                </>
            )
        );
    }

    renderCountdownText() {
        const {
            countdown: {
                days, hours, minutes, seconds,
            },
        } = this.props;

        return ((days + hours + minutes + seconds > 0)
            && (
            <div block="FlashSalesIndicator" elem="CountdownText">
                <i className="fa fa-clock-o" aria-hidden="true" />
                &nbsp;
                { __('Offer on time and while stocks last') }
            </div>
            ));
    }

    renderCountdown() {
        const {
            countdown: {
                days, hours, minutes, seconds,
            },
        } = this.props;

        return ((days + hours + minutes + seconds > 0)
            && (
                <span block="FlashSalesIndicator" elem="Countdown">
                    { __('Ends in') }
                    &nbsp;
                    { days > 0 && (
                    <strong>
                        { days }
                        d
                    </strong>
                    ) }
                    &nbsp;
                    { hours > 0 && (
                    <strong>
                        { hours }
                        h
                    </strong>
                    ) }
                    &nbsp;
                    { minutes > 0 && (
                    <strong>
                        { minutes }
                        m
                    </strong>
                    ) }
                    &nbsp;
                    <strong>
                    { seconds }
                    s
                    </strong>
                    &nbsp;
                </span>
            )
        );
    }

    renderInfoPopUp() {
        const { flashsale: { cms_block }, sku } = this.props;

        return (
            cms_block && <CmsPopup id={ sku } cms_block={ cms_block } />
        );
    }

    renderFlashSaleClosed() {
        const { flashsale: { closed_image } } = this.props;

        return (
            <Image
              src={ closed_image }
              mix={ {
                  block: 'Footer',
                  elem: 'Logo',
              } }
            />
        );
    }

    renderContent() {
        const { flashsale: { is_closed } } = this.props;

        if (is_closed) {
            return this.renderFlashSaleClosed();
        }

        return this.renderFlashSaleActive();
    }

    renderFlashSaleActive() {
        const { isCompactVersion } = this.props;

        return (
            <>
                { !isCompactVersion && this.renderSlideBar() }
                { this.renderInfoPopUp() }
            </>
        );
    }

    render() {
        const { flashsale: { discount_code }, mix } = this.props;

        return (
            <div block="FlashSalesIndicator" mix={ mix } mods={ { DiscountCode: discount_code } }>
                { this.renderContent() }
            </div>
        );
    }
}

export default FlashSalesIndicatorComponent;
