import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { FlashSaleType } from 'Type/FlashSaleType';
import { countdown } from 'Util/Countdown';

import FlashSalesIndicator from './FlashSalesIndicator.component';

/** @namespace Pwa/Component/FlashSalesIndicator/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    promo: state.McRealTimePromoReducer.promo,
});

/** @namespace Pwa/Component/FlashSalesIndicator/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // ADD dispatch
});

/** @namespace Pwa/Component/FlashSalesIndicator/Container */
export class FlashSalesIndicatorContainer extends PureComponent {
    static propTypes = {
        flashsale: FlashSaleType.isRequired,
        sku: PropTypes.string.isRequired,
        isInfoIcon: PropTypes.bool,
        isCompactVersion: PropTypes.bool,
        mix: PropTypes.object,
        promo: PropTypes.array.isRequired,
    };

    static defaultProps = {
        isInfoIcon: true,
        isCompactVersion: false,
        mix: {},
    };

    state = {
        countdown: {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        },
    };

    componentDidMount() {
        const { flashsale: { time_end } } = this.props;
        this.carouselInterval = countdown(time_end, (countdown) => this.setState({ countdown }));
    }

    componentWillUnmount() {
        clearTimeout(this.carouselInterval);
    }

    render() {
        const {
            flashsale, sku, isInfoIcon, isCompactVersion, mix, promo,
        } = this.props;
        const { countdown } = this.state;

        const { discount_code: rt_discount_code, items_left: rt_item_left } = promo[sku] || {};
        const { discount_code } = flashsale;

        const rt_flashsale = (rt_discount_code === discount_code) ? {
            ...flashsale,
            items_remaining: rt_item_left,
        } : flashsale;

        return (
            <FlashSalesIndicator
              mix={ mix }
              sku={ sku }
              flashsale={ rt_flashsale }
              countdown={ countdown }
              isInfoIcon={ isInfoIcon }
              isCompactVersion={ isCompactVersion }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashSalesIndicatorContainer);
