import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './DimensionFormatter.style';

/** @namespace Pwa/Component/DimensionFormatter/Component */
export class DimensionFormatterComponent extends PureComponent {
    static propTypes = {
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        depth: PropTypes.number.isRequired,
        mix: PropTypes.object,
    };

    static defaultProps = {
        mix: {},
    };

    render() {
        const {
            width, height, depth, mix,
        } = this.props;

        return (width > 0 && height > 0 && depth > 0) && (
            <p block="DimensionFormatter" mix={ mix }>
                cm.&nbsp;
                { width }
                &nbsp;x&nbsp;
                { depth }
                &nbsp;x&nbsp;
                { height }
            </p>
        );
    }
}

export default DimensionFormatterComponent;
